import React from "react"
import { Link } from "gatsby"

const PostsList = ({ posts }) => {
  return (
    <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const tags = post.frontmatter.tags || []
          const readingTime = post.fields.readingTime?.text || ''

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{ title }</span>
                    </Link>
                  </h2>
                  <div>
                    {tags.map(tag => {
                      return (
                        <a className="tag" href={`/tags/${tag}`}>
                          #{ tag }
                        </a>
                      )
                    })}
                    <small className="time">{ readingTime }</small>
                  </div>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
              <hr />
            </li>
          )
        })}
      </ol>
  )
}

export default PostsList
